import React, { useState, useEffect } from 'react';

import Layout from '../../components/layout';
import { baseApiUrl } from '../../configs';

import './index.css';

const ContactNumber = () => {
  const [phoneNumber, setPhoneNum] = useState('18442131328');
  const [contactDetails, setContactDetails] = useState([]);

  useEffect(() => {
    fetch(`${baseApiUrl}/contact-us-travel-market?ibe=true`)
      .then(response => response.json())
      .then(({ data }) => setContactDetails(data));
  }, []);

  return (
    <Layout
      isStaticPage
      staticPhoneNumber={phoneNumber}
      passPhoneNumber={phoneNumber => {
        setPhoneNum(phoneNumber);
      }}
    >
      <div className="table-concept">
        <h2 className="table-title">Travel Market Contacts</h2>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Travel Sector</th>
              <th>Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {contactDetails.map((detail, index) => {
              return (
                <tr key={index + 1}>
                  <td>{index + 1}</td>
                  <td>{detail.travelMarket}</td>
                  <td>{detail.phoneNumber}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default ContactNumber;
